import React, { useRef } from "react";
import { Link } from "gatsby";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

import Breadcrumb from "../../components/Breadcrumb";
import Layout from "../../components/Layout";
import GoogleAds from "../../components/GoogleAds";
import * as Constants from "../../lib/constants";
import {
  getGooglePlayURLFromAppID,
  getAppStoreURLFromAppID,
  getWordLangKey,
} from "../../lib/helpers";

import * as styles from "./Word.module.css";
import CourseItems from "../../components/CourseItems";

const Word = ({ pageContext, path }) => {
  const {
    displayedCourses,
    courseWithCurrentWord,
    courseWithCurrentWordFullPath,
    langToLearn,
    langUser,
    coursesByLangIndexPagePath,
    courseParentPath,
    courseLanguage,
    word,
    wordPro,
    wordSimple,
    translation,
    audioURL,
    imageURL,
    featureImageURL,
    plural,
    female,
    female_plural,
    preterite,
    participle2,
    auxiliaryVerb,
    comparative,
    superlative,
    exampleSentence,
    title,
    subtitle,
    labelWordTranslation,
    featureImageDesc,
    labelPluralForm,
    labelFemaleForm,
    labelFemalePluralForm,
    labelPastTense,
    labelPastParticle,
    labelAuxiliaryVerb,
    labelComparative,
    labelSuperlative,
    labelExampleSentence,
    metaDesc,
    rootBreadcrumbLabel,
    labelBreadcrumbVocabularyLists,
    playText,
    relatedCourseTitlesMap,
    recommendationsTitle,
    downloadTitle,
    downloadDescription,
    downloadAndroid,
    downloadIos,
    pagePath,
  } = pageContext;

  const imageDesc = `${word}-${translation}`;

  const wordAudioRefs = useRef({});
  const appData = Constants.AVAILABLE_APPS.find((item) => item.key === getWordLangKey(langToLearn));

  const wordFields = [
    {
      key: "word",
      label: labelWordTranslation,
      value: word,
      valueFontSize: 35,
      audioURL: audioURL,
    },
  ];

  const extraFields = [
    { key: "simple", value: wordSimple, label: "Kana (Hiragana, Katakana)" },
    {
      key: "pro",
      value: wordPro,
      label: langToLearn === Constants.ALL_LANGUAGES.jaJP ? "Romaji" : undefined,
    },
    { key: "plural", label: labelPluralForm, value: plural },
    { key: "female", label: labelFemaleForm, value: female },
    { key: "female_plural", label: labelFemalePluralForm, value: female_plural },
    { key: "preterite", label: labelPastTense, value: preterite },
    { key: "participle2", label: labelPastParticle, value: participle2 },
    { key: "auxiliaryVerb", label: labelAuxiliaryVerb, value: auxiliaryVerb },
    { key: "comparative", label: labelComparative, value: comparative },
    { key: "superlative", label: labelSuperlative, value: superlative },
    { key: "exampleSentence", label: labelExampleSentence, value: exampleSentence },
  ];

  extraFields.forEach((field) => {
    if (field.value) {
      wordFields.push({
        label: field.label,
        value: field.value,
        valueFontSize: field.valueFontSize || 20,
      });
    }
  });

  return (
    <Layout
      title={title}
      metaDesc={metaDesc}
      iosID={appData.iosID}
      ogImage={featureImageURL}
      ogUrlPath={pagePath}
      canonicalPath={pagePath}
    >
      <main>
        <Breadcrumb
          items={[
            { label: rootBreadcrumbLabel, to: `/courses?l=${langUser}` },
            {
              label: labelBreadcrumbVocabularyLists,
              to: coursesByLangIndexPagePath,
            },
            ...(courseWithCurrentWord && courseWithCurrentWordFullPath
              ? [
                  {
                    label: courseWithCurrentWord.name,
                    to: courseWithCurrentWordFullPath,
                  },
                ]
              : []),
            { label: wordPro ? `${word} - ${wordPro}` : word },
          ]}
        />
        <header
          style={{
            display: "flex",
            paddingBottom: 20,
            borderBottom: "1px solid #dbdbdb",
          }}
        >
          <img alt={imageDesc} title={imageDesc} height={100} width={100} src={imageURL} />
          <div className={styles.titleSubtitleContainer}>
            <h1 className={styles.title}>{title}</h1>
            <p
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </div>
        </header>
        <div
          style={{
            textAlign: "center",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          {wordFields.map((field, index) => (
            <div key={index} style={{ marginBottom: 15 }}>
              <div style={{ marginBottom: 5 }}>
                <span>{field.label}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <b style={{ fontSize: field.valueFontSize, marginLeft: field.audioURL ? 15 : 0 }}>
                  {field.value}
                </b>
                {field.audioURL && (
                  <>
                    <button
                      name={`${playText} ${word}`}
                      onClick={() => {
                        wordAudioRefs.current[field.key].play();
                      }}
                      aria-label={playText}
                      style={{
                        display: "flex",
                        backgroundColor: "transparent",
                        border: 0,
                        cursor: "pointer",
                      }}
                    >
                      <StaticImage
                        src="../../images/play-button-arrowhead.svg"
                        alt={playText}
                        height={16}
                        width={16}
                      />
                    </button>
                    <audio
                      ref={(el) => {
                        wordAudioRefs.current[field.key] = el;
                      }}
                      src={field.audioURL}
                      preload="none"
                    ></audio>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <div style={{ padding: "0 15px", margin: "5px 0", boxSizing: "border-box" }}>
          <GoogleAds slot="9202174501" />
        </div>
        {featureImageURL && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LazyLoad once style={{ minHeight: 300, margin: "10px 0" }}>
              <img
                src={featureImageURL}
                style={{ maxWidth: "100%", width: 500 }}
                title={`${featureImageDesc}`}
                alt={`${featureImageDesc} svg`}
              />
            </LazyLoad>
          </div>
        )}
        <div>
          {displayedCourses.map((course) => {
            return (
              <div key={course.id} style={{ marginTop: 50 }}>
                {course.youtubeVideoID && (
                  <div className={styles.iframeYoutubeContainer}>
                    <LiteYouTubeEmbed
                      id={course.youtubeVideoID}
                      title={`${course.name} YouTube video`}
                    />
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link
                    to={
                      langUser === Constants.ALL_LANGUAGES.enUS
                        ? `/${courseParentPath}/${course.id}`
                        : `/${getWordLangKey(langUser)}/${courseParentPath}/${course.id}`
                    }
                    style={{ textDecoration: "none", color: "#1c2f46" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={course.imageURL}
                        alt={course.name}
                        style={{ height: 50, width: 50, marginRight: 10 }}
                      />
                      <h2>{relatedCourseTitlesMap[course.id]}</h2>
                    </div>
                  </Link>
                </div>
                <CourseItems items={course.items} playText={playText} />
                <div style={{ padding: "0 15px", margin: "5px 0", boxSizing: "border-box" }}>
                  <GoogleAds slot="9202174501" />
                </div>
              </div>
            );
          })}
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Link
              style={{ textDecoration: "none", color: "#136ad5" }}
              to={coursesByLangIndexPagePath}
            >
              <span>{recommendationsTitle}</span>
            </Link>
          </div>
        </div>
        <div style={{ textAlign: "center", paddingBottom: 50, marginTop: 50 }}>
          <h2>{downloadTitle}</h2>
          <p>{downloadDescription}</p>
          <div className={styles.footerStoreButtonsContainer}>
            <a href={getAppStoreURLFromAppID(appData.iosID)}>
              <img src="/download-app-store.svg" alt={downloadIos} height={45} width={135} />
            </a>
            <a href={getGooglePlayURLFromAppID(appData.androidID)}>
              <img src="/download-google-play.svg" alt={downloadAndroid} height={45} width={152} />
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Word;
